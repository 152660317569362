<template>
  <b-modal
    id="ModalAddNewMKP"
    size="sm"
    :title="$t('Asociar a un Marketplace')"
    no-close-on-backdrop
    @close="() => close(false)"
    hide-footer
  >
    <form-render :form.sync="formNewMKP"
      :fields="fieldsNewMKP"
      containerButtonsClass="col-sm-12"
      :key="keyFormRenderNewMKP"
      ref="formRenderNewMKP"
      @send="addNewMKP"
    />
    <div>
      <b-button class="float-right mt-1 mb-1" variant="warning" @click="ok" :disabled="loading.addingMKP">
        <span v-if="!loading.addingMKP" class="mb-1">Agregar</span>
        <span class="mt-1 p-2" v-if="loading.addingMKP"><b-spinner small  /> </span>
      </b-button>
    </div>
  </b-modal>
</template>
 
<script>

export default {
  name: 'ModalAddNewMKP',
  props: ['marketplaces', 'refresh'],
  data() {
    return {
      fieldsNewMKP: [],
      formNewMKP: {},
      keyFormRenderNewMKP: 0,
      loading: {
        addingMKP: false
      }
    }
  },
  watch: {
    marketplaces() {
      this.fieldsNewMKP[0].options = this.marketplaces.MKP.map(mkp => {
        return {
          ...mkp,
          text: mkp.name1
        }
      })
      this.keyFormRenderNewMKP++
    }    
  },
  mounted() {
    this.setInitialData()
  },
  methods: {
    setInitialData() {
      this.fieldsNewMKP = [
        { fieldType: 'FieldSelect', useLabel: true, name: 'id', label: this.$t('Marketplaces'), searchOnType: this.searchFn, containerClass: 'col-12 col-md-9 fieldSelect', validation: 'required' }      
      ]      
    },
    addNewMKP() {
      this.loading.addingMKP = true
      const params = {
        company_id: this.marketplaces.companyId,
        marketplace_id: this.formNewMKP.id.id
      }
      this.$store.dispatch('fetchService', { name: 'addNewMarkeplaceCompany', params, hideAlert: true, 
        onSuccess: () => {
          this.loading.addingMKP = false
          this.close(true)
        }, 
        onError: () => {
          this.loading.addingMKP = false
          this.$alert(this.$t('msg-problema-agregar-mkp')) 
        }
      })
    },
    ok(e) {
      e.preventDefault()
      this.$refs.formRenderNewMKP.checkForm()
    },
    close(fromAddMKP) {
      this.formNewMKP.id = null
      this.$bvModal.hide('ModalAddNewMKP')
      if (fromAddMKP) {
        this.$success(this.$t('msg-exito-agregar-mkp'))
        this.$emit('refresh')
      }
    }
  }

}
</script>

<style lang="scss">
  .invalid-mail-company{
    border-color: red;
  }
</style>
