<template>
  <b-modal
    id="ModalMKPConfig"
    modal-class="dialog-800"
    :title="$t('Configuración de Marketplace')"
    no-close-on-backdrop
    @close="() => close(false)"
    hide-footer
  >
    <form-render :form.sync="formMKPConfig"
      :fields="fieldsMKPConfig"
      containerButtonsClass="col-sm-12"
      :key="keyFormRenderMKPConfig"
      ref="formRenderMKPConfig"
      @send="addMKPConfig"
    />
    <div>
      <b-button class="float-right mt-1 mb-1" variant="warning" @click="ok" :disabled="loading.addingMKP">
        <span v-if="!loading.addingMKP" class="mb-1">Guardar</span>
        <span class="mt-1 p-2" v-if="loading.addingMKP"><b-spinner small  /> </span>
      </b-button>
    </div>
  </b-modal>
</template>
 
<script>

export default {
  name: 'ModalMKPConfig',
  props: ['marketplaceData', 'refresh'],
  data() {
    return {
      fieldsMKPConfig: [],
      formMKPConfig: {},
      keyFormRenderMKPConfig: 0,
      loading: {
        addingMKP: false
      }
    }
  },
  watch: {
    marketplaceData() {
      this.createInputs()
    }    
  },
  mounted() {
    if (this.marketplaceData) this.createInputs()
  },
  methods: {
    addMKPConfig() {
      const queryParams = {
        ...this.marketplaceData,
        customization: JSON.stringify(this.formMKPConfig)
      }
      this.loading.addingMKP = true
      const params = {
        company_marketplace_id: this.marketplaceData.id
      }
      this.$store.dispatch('fetchService', { name: 'configAPiKeyMarkeplaceCompany', queryParams, params, hideAlert: true, 
        onSuccess: () => {
          this.loading.addingMKP = false
          this.close(true)
        }, 
        onError: () => {
          this.loading.addingMKP = false
          this.$alert(this.$t('msg-problema-configurar-key-mkp')) 
        }
      })
    },
    createInputs () {
      this.fieldsMKPConfig = []
      const pasedObject = JSON.parse(this.marketplaceData.customization)
      Object.keys(pasedObject).forEach(key => {
        this.fieldsMKPConfig.push(
          { fieldType: 'FieldTextarea', useLabel: true, name: key, label: key, containerClass: 'col-6 container-info' }
        )
        if (pasedObject[key]) this.formMKPConfig[key] = typeof pasedObject[key] === 'object' ? JSON.stringify(pasedObject[key]) : pasedObject[key]
      })
      this.keyFormRenderMKPConfig++
    },
    ok(e) {
      e.preventDefault()
      this.$refs.formRenderMKPConfig.checkForm()
    },
    close(fromAddMKP) {
      this.formMKPConfig = {}
      this.$bvModal.hide('ModalMKPConfig')
      if (fromAddMKP) {
        this.$success(this.$t('msg-exito-configurar-key-mkp'))
        this.$emit('refresh')
      }
    }
  }

}
</script>

<style lang="scss">
  .invalid-mail-company{
    border-color: red;
  }
</style>
