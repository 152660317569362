<template>
  <div>
    <b-modal
    id="ModalMassiveLoad"
    modal-class="dialog-600"
    no-close-on-esc
    no-close-on-backdrop
    hide-footer
    @close="() => close(false)"
  >
  <template #modal-title>
    <h4 class="create-seller">Creación de Seller/Bodega</h4>
  </template>
  <small>Esta acción puede tomar varios minutos</small>
    <p class="mt-3 import-file">Archivo de importación (<a class="lnk lnk-primary custom-link" :href="excelDocUrl" target="_blank" rel="noopener noreferrer">Descargar Formato</a>)</p>
    <div class="row" >
      <div class="col-md-10">
        <b-col class="px-0 pb-1 pb-md-0 col" v-if="form.fileMKP" v-cloak>
          <div class="w-100 p-2 file-drop-area">
            <b-input-group class="invoice-list-area">
              <b-form-input
                  placeholder="File name..."
                  v-model="form.fileMKP.name"
                  class="bg-gray-input border-0"
              />
              <b-input-group-append class="bg-gray-input">
                <b-button variant="outline" class="border-0" size="sm" @click="removeFileMKP()" :disabled="loading.uploadMKP">
                  <feather-icon icon="XIcon" size="20"/>
                </b-button>
              </b-input-group-append>
            </b-input-group>
          </div>
        </b-col>
        <b-col class="px-0 pb-1 pb-md-0 col" v-if="!form.fileMKP">
          <div class="file-drop-area" >
            <input type="file" name="fileMKP" id="fileMKP" class="file-input invisible" @change="changeFileMKP" accept=".xls, .xlsx"/>
            <label for="fileMKP" class="w-100 text-center cursor-pointer m-0 custom-label">
              <feather-icon v-if="!loading.uploadMKP" icon="UploadCloudIcon" size="22"/>
              <span v-if="!loading.uploadMKP" >Seleccionar archivo</span>
            </label>
          </div>
        </b-col>
        <small><strong>*Solo puede crear una bodega por seller</strong></small>
      </div>
      <div class="col-md-12 d-flex align-items-center justify-content-end mt-3">
        <b-button  variant="warning" @click="uploadMKPFile()" :disabled="loading.uploadMKP || !form.fileMKP">
          <b-spinner v-if="loading.uploadMKP" small></b-spinner>
          <span v-else>Crear</span>
        </b-button>
      </div> 
    </div>
  </b-modal>
  </div>
</template>

<script>
import { environment } from '@/environments/environment'
import BaseServices from '@/store/services/index'
export default {
  name: 'ModalMassiveLoad',
  props: ['marketplace', 'sessionUser'],
  data() {
    return { 
      excelDocUrl: null,
      form: {
        fileMKP: null
      },
      loading: {
        uploadMKP: false
      },
      baseService: new BaseServices(this),
      mkpInfo: {
        country_code: null,
        id: null
      },
      sessionUserInfo: undefined
    }
  },
  watch : {
    marketplace () {
      this.setMarketplace()
    },
    sessionUser () {
      this.sessionUserInfo = this.sessionUser
    }
  },
  mounted() {
    this.setInitialData()
  },
  methods: {
    setInitialData () {
      if (Object.keys(this.marketplace).length > 0) {
        this.setMarketplace()
      }
      if (!!this.sessionUser) {
        this.sessionUserInfo = this.sessionUser
      }
    },
    setMarketplace () {
      this.mkpInfo.country_code = this.marketplace.country.code
      this.mkpInfo.id = this.marketplace.id
      this.excelDocByCountry()
    },
    excelDocByCountry () {
      this.excelDocUrl = `${environment.dubaiDocsCloud}/seller-bodega/bulk-import-formats/${this.mkpInfo.country_code.toUpperCase()}.xlsx`
    },
    close(formLoad) {
      this.removeFileMKP()
      this.loading.uploadMKP = false
      this.$bvModal.hide('ModalMassiveLoad')
      if (formLoad) {
        this.$success(this.$t('msg-exito-importar-archivo-email'))
        this.$emit('refresh')
      }
    },
    changeFileMKP(e) {
      this.form.fileMKP = e.target.files[0]
    },
    removeFileMKP() {
      this.form.fileMKP = null
      this.keyFormRender++
    },
    uploadMKPFile () {
      this.loading.uploadMKP = true
      const data = {
        file: this.form.fileMKP,
        marketplace_id: this.mkpInfo.id,
        country_code: this.mkpInfo.country_code,
        email: this.sessionUserInfo?.email
      }
      const params = {}
      this.baseService.callUploadFileCustomParams('postSellerMassive', data, params)
        .then(response => {
          this.close(true)
        })
        .catch((err) => {     
          this.$alert(this.$t('msg-problema-cargar-datos', {code: err.error}))
          this.loading.uploadMKP = false
        })
    }
  }
}
</script>

<style lang="scss" scoped>
  .modal-title {
    font-size: 15px;  
  }
  div.file-drop-area {
    padding: 0.4rem 0;
    border: 1.7px solid #B9B9C7;
    border-radius: 0.357rem;
    max-height: 300px;
    overflow-y: auto;
    input.file-input {
      position: absolute;
      height: 0;
    }
  }
  .invoice-list-area{
    padding-bottom: 8px;
  }
  .bg-gray-input {
    background-color: #f7f7f9 !important;
  }
  .custom-label {
    display: flex;
    align-items: center;
    justify-content: center;
    color: #AEAEAE;
    font-size: 15px;
  }
  .custom-label svg {
    stroke-width: 1;
    margin-right: 0.5rem;
  }
  .custom-link {
    text-decoration: underline;
    color: #1B4284 !important;
    font-weight: bold;
  }
  .import-file {
    font-size: 13px;
  }
  .create-seller {
    margin-bottom: 0;
  }
</style>