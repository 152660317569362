<template>
 <b-card>
    <div class="d-flex justify-content-end mb-1" v-if="show.newMKPButton">
      <b-button @click="() => openModal('ModalAddNewMKP')" variant="warning">
        <feather-icon icon="ArchiveIcon"/> Asignar nuevo Marketplace
      </b-button>
    </div>
    <div class="d-flex justify-content-end mb-1" v-if="show.newMKPButtonBulkLoad">
      <b-button @click="() => openModal('ModalMassiveLoad')" variant="success">
        <feather-icon icon="UploadIcon"/> Carga Masiva
      </b-button>
    </div>
    <div class="mx-2">
      <table-render v-show="show.tableMKP" :key="keyTableMKPRender" id="companyMKPList" :rows.sync="rowsTableMKP" :schema="schemaTableMKP">
        <!-- Redirecciones -->
        <template #redirections="scope">
          <template v-if="show.edit">
            <b-button size="sm" variant="link" @click="() => redirect('company', companyData.marketplace ? scope.rowdata.id : scope.rowdata.marketplace_id)" v-b-tooltip.hover.top="'Editar'" class="px-0">
              <feather-icon icon="EditIcon"/>
            </b-button>
            |
          </template>          
          <template v-if="companyData && companyData.marketplace">
            <b-button size="sm" variant="link" @click="() => redirect('billing', scope.rowdata.id)" v-b-tooltip.hover.top="'Ver facturas'" class="px-0">
              <feather-icon icon="FileIcon" class="text-success"/>
            </b-button>
            |
            <b-button size="sm" variant="link" @click="() => redirect('cyberForm', scope.rowdata.id)" v-b-tooltip.hover.top="'Formulario cyber day'" class="px-0">
              <feather-icon icon="FileIcon" :class="`${scope.rowdata.show_cyber_form ? 'text-warning' : 'text-dark'}`"/>
            </b-button>
          </template>
          <template v-else>
            <b-button size="sm" variant="link" @click="() => confirmDeleteMKP(scope.rowdata.marketplace_id)" class="px-0">
              <feather-icon icon="TrashIcon" class="text-danger"/>
            </b-button>
          </template>          
        </template>
        <!-- Tabla else -->
        <template #status="scope">
          <b-badge :variant="scope.rowdata.statusClass">{{scope.rowdata.status}}</b-badge>
        </template>
        <template #aditionConfig="scope">
          <b-button v-if="scope.rowdata.code === 'ripley'" size="sm" variant="link" @click="() => openModal('ModalMKPConfig', scope.rowdata, 'config')" class="text-danger px-0">
            Configurar Api-key
          </b-button>
        </template>
        <!--  -->
      </table-render>
      <div class="table-render-skeleton" v-show="!show.tableMKP">
        <b-skeleton-table :rows="10" :columns="3"/>
      </div>
    </div>
    <modal-add-new-mkp :marketplaces="availableMKP" @refresh="getMKPData"/>
    <modal-mkp-config :marketplaceData="selectMKP" @refresh="getMKPData"/>
    <modal-massive-load :marketplace="companyData" @refresh="getMKPData" :sessionUser="sessionUser"/>
 </b-card>
</template>

<script>
import { mapGetters } from 'vuex'
import { environment } from '@/environments/environment'
import ModalAddNewMkp from './ModalAddNewMKP.vue'
import ModalMkpConfig from './ModalMKPConfig.vue'
import ModalMassiveLoad from './ModalMassiveLoad.vue'

export default {
  name: 'tab-markeplace-company',
  components: { ModalAddNewMkp, ModalMkpConfig, ModalMassiveLoad },
  data () {
    return {
      show: {
        newMKPButton: false,
        newMKPButtonBulkLoad: false,
        tableMKP: false,
        tableMKPAdmin: false,
        edit: false
      },
      userData: null,
      keyTableMKPRender: 0,
      rowsTableMKP: [],
      schemaTableMKP: [],
      companyData: {},
      links: {
        company: '/companies/',
        billing: '/billing/',
        cyberForm: '/cyber-form/'
      },
      availableMKP: [],
      companyId: null,
      selectMKP: null,
      sessionUser: null
    }
  },
  computed: {
    ...mapGetters({
      mySession: 'getSession',
      companyInformation: 'getCompanyInformation',
      allSellersMarketplace: 'getAllSellersMarketplace',
      allMarketplacesCompany: 'getAllMarketplacesCompany'
    })
  },
  watch: {
    mySession () {
      if (this.mySession.id) this.setInitialData()
    },
    companyInformation() {
      this.handleCompanyInfo()
    },
    allSellersMarketplace() {
      this.show.edit = true
      this.rowsTableMKP = this.allSellersMarketplace
      this.show.tableMKP = true
    },
    allMarketplacesCompany() {
      this.show.edit = true
      this.rowsTableMKP = this.allMarketplacesCompany.company_MKP
      this.show.tableMKP = true
      this.availableMKP = {
        MKP: this.allMarketplacesCompany.available_MKP,
        companyId: this.$route.params?.id
      }
    }
  },
  mounted () {
    if (this.mySession.id) this.setInitialData()
  },
  methods: {
    setInitialData() {
      // En caso de que no haya datos de la company se llama el endpoint de companyData, es necesario para saber cual API de MKP llamar
      if (!Object.keys(this.companyInformation).length) this.$store.dispatch('fetchService', { name: 'getCompanyInformation', params: { company_id: this.$route.params?.id }})
      else this.handleCompanyInfo()
    },
    handleCompanyInfo() {
      this.companyData = this.companyInformation?.rows?.company
      this.getMKPData()
      this.createTable()
      if (this.companyData.marketplace) {
        this.show.newMKPButtonBulkLoad = true
        this.sessionUser = this.$session.get('cas_user')
      }
    },
    getMKPData() {
      const { marketplace } = this.companyData
      this.$store.dispatch('fetchService', { name: marketplace ? 'getAllSellersMarketplace' : 'getAllMarketplacesCompany', params: {company_id: this.$route.params?.id}})
    },
    createTable() {
      // Condicion para saber que datos mostrar en la tabla
      if (this.companyData.marketplace) {
        this.schemaTableMKP = [
          { label: 'ID', key: 'id'},
          { label: 'Nombre del Seller', key: 'fullName' },
          { label: 'Código', key: 'code' },
          { label: '', key: 'redirections', useSlot: true }
        ]
      } else {
        this.schemaTableMKP = [
          { label: 'ID', key: 'marketplace_id'},
          { label: 'Nombre del Marketplace', key: 'name1' },
          { label: 'Estado', key: 'status', useSlot: true },
          { label: 'Configuración Adicional', key: 'aditionConfig', useSlot: true },
          { label: '', key: 'redirections', useSlot: true }
        ]
      }
    },
    redirect (key, id) {
      this.m_openWinBlank(`${this.links[key]}${id}`)
    },
    confirmDeleteMKP(_id) {
      this.$yesno(this.$t('msg-pregunta-eliminar-elemento'), () => this.deleteMKP(_id))
    },
    deleteMKP(id) {
      const params = {
        company_id: this.$route.params?.id,
        marketplace_id: id
      }
      this.$store.dispatch('fetchService', { name: 'deleteMarkeplaceCompany', params, hideAlert: true, 
        onSuccess: () => {
          this.$success(this.$t('msg-exito-eliminar-mkp'))
          this.getMKPData()
        }, 
        onError: (err) => this.$alert(this.$t('msg-problema-eliminar-elemento', {code: err})) 
      })

    },
    openModal (name, mkpData = null, type = null) {
      if (type === 'config') this.selectMKP = mkpData
      this.$bvModal.show(name)
    }
  }
}
</script>
